import React, { useEffect, useRef, useState, useCallback } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    boxShadow: "0 3px 5px 2px rgba(105, 135, 255, 0.3)",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    margin: "0 8px",
    "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: "0 3px 5px 2px rgba(105, 135, 255, 0.4)",
    },
}));

const IdleTimeout = ({ logout, idleTime, notificationTime, isLoggedIn }) => {
    const [sessionEndNotification, setSessionEndNotification] = useState(false);
    const [isHandlingLogout, setIsHandlingLogout] = useState(false);
    const sessionNotificationTimer = useRef(null);
    const timeoutID = useRef(null);

    const timeInMinutes = (value) => value / 60 / 1000;

    const inactiveSession = useCallback(() => {
        setSessionEndNotification(true);
        sessionNotificationTimer.current = setTimeout(() => {
            setIsHandlingLogout(true);
            logout();
        }, 1000 * 60 * notificationTime);
    }, [logout, notificationTime]);

    const resetTimer = useCallback(() => {
        if (timeoutID.current) clearTimeout(timeoutID.current);
        startTimer();
    }, []);

    const startTimer = useCallback(() => {
        timeoutID.current = setTimeout(inactiveSession, 1000 * 60 * idleTime);
        localStorage.setItem("startIdle", timeInMinutes(Date.now()));
    }, [idleTime, inactiveSession]);

    const handleSessionContinue = () => {
        if (sessionNotificationTimer.current) clearTimeout(sessionNotificationTimer.current);
        setSessionEndNotification(false);
        resetTimer();
    };

    const handleSessionLogOut = () => {
        if (sessionNotificationTimer.current) clearTimeout(sessionNotificationTimer.current);
        if (timeoutID.current) clearTimeout(timeoutID.current);
        setSessionEndNotification(false);
        setIsHandlingLogout(true);
        logout();
    };

    useEffect(() => {
        if (!isLoggedIn || isHandlingLogout) return;

        const events = ["mousemove", "mousedown", "keypress", "touchmove"];
        events.forEach(event => document.addEventListener(event, resetTimer, false));
        startTimer();

        return () => {
            events.forEach(event => document.removeEventListener(event, resetTimer, false));
            if (timeoutID.current) clearTimeout(timeoutID.current);
            if (sessionNotificationTimer.current) clearTimeout(sessionNotificationTimer.current);
        };
    }, [isLoggedIn, isHandlingLogout, startTimer, resetTimer]);

    if (!isLoggedIn) return null;

    return (
        <Dialog
            open={sessionEndNotification}
            onClose={handleSessionContinue}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Session Timeout Warning</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your session is about to end. Do you want to extend your session?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={handleSessionLogOut} color="secondary">
                    Log Out
                </StyledButton>
                <StyledButton onClick={handleSessionContinue} color="primary">
                    Continue Session
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

export default IdleTimeout;
