import * as CryptoJS from 'crypto-js';

export const generateCodeVerifier = () => {
    const codeVerifier = [...Array(128)]
        .map(() => (Math.random() * 36 | 0).toString(36))
        .join('');
    localStorage.setItem("codeVerifier", codeVerifier);
    return codeVerifier;
};

export const generateCodeChallenge = (codeVerifier) => {
    const hashedVerifier = CryptoJS.SHA256(codeVerifier);
    const codeChallenge = CryptoJS.enc.Base64.stringify(hashedVerifier);
    return codeChallenge
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
};
