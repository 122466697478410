export const OAuth2Config = {
    // Ping Federate.
    authClientId: "APM0003218BANKANALYS",
    authUrl: "https://fdc-fedsso.firstdata.com/as/authorization.oauth2",
    accessTokenUrl: "https://bankintelligence.fiserv.com/user/getToken",
    refreshTokenUrl: "https://bankintelligence.fiserv.com/user/refreshtoken",
    callbackUrl: import.meta.env.DEV
        ? "https://localhost:3000/callback"
        : `${import.meta.env.VITE_BASE_URL}/callback`,
    logoutCallback: import.meta.env.DEV
        ? "https://localhost:3000"
        : `${import.meta.env.VITE_BASE_URL}`,
    logoutUrl: "https://fdc-fedsso.firstdata.com/idp/startSLO.ping",
    identityProvider: "", // Add if needed
    challengeMethod: "S256",
    codeChallenge: "kTi0ahQXsDzYaGJkKMrQIFzxO8TYpbvRd3YQUPB-FpY",
};
