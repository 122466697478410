import { ReactComponent as SignOutIcon } from "assets/icons/sign-out.svg";
import {
    logoutUser,
    setShowChangePassword,
} from "routes/AuthenticationRoute/AuthenticationSlice";

export const userMenu = [
    //{ label: "Change Password", onClick: setShowChangePassword },
    { label: "Logout", icon: SignOutIcon, onClick: logoutUser },
];
