import React, { useEffect, useState } from "react";
import {
    CssBaseline,
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
    Box,
    IconButton,
} from "@mui/material";
import { ThemeProvider } from "styled-components";
import { keyframes, styled } from "@mui/system";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";

import MultiStepDialog from "./components/modal/MultiStepDialog";
import { muiTheme } from "./theme/theme";
import { primaryRoutes } from "constants/routes";
import PrimaryLayout from "components/layout/PrimaryLayout/PrimaryLayout";
import ToastContainer from "components/@bank-ui/core/Toast/ToastContainer";
import { GlobalStyles } from "./theme/globalStyle";
import ErrorBoundary from "components/ErrorBoundary";
import IdleTimeout from "components/IdleTimeout";
import {
    refreshToken,
    logoutUser,
} from "./routes/AuthenticationRoute/AuthenticationSlice";
import redirectTPeersDashboard from "utils/redirecttoPeerListDashboard";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const PulsingIconButton = styled(IconButton)`
    animation: ${pulse} 1.5s infinite;
    width: 3.5rem;
    height: 3.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    &:hover {
        background-color: rgba(0, 0, 0, 0.9);
    }
`;

function App() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(true);
    const isRootRoute = location.pathname === "/";

    const userObject = JSON.parse(sessionStorage.getItem("userObject"));
    const isLoggedIn = userObject !== null && userObject !== undefined;

    useEffect(() => {
        if (isLoggedIn) {
            const newRefreshToken = sessionStorage.getItem("refresh_token");
            dispatch(
                refreshToken(
                    userObject.userName,
                    userObject.token,
                    newRefreshToken
                )
            );
        }
        redirectTPeersDashboard(location, history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <ThemeProvider theme={muiTheme}>
                    <GlobalStyles />
                    <ErrorBoundary>
                        <PrimaryLayout>{primaryRoutes}</PrimaryLayout>
                    </ErrorBoundary>
                    <IdleTimeout
                        logout={() => {
                            dispatch(logoutUser());
                        }}
                        idleTime={5}
                        notificationTime={1}
                        isLoggedIn={isLoggedIn}
                    />
                    <ToastContainer />
                    {isRootRoute && (
                        <>
                            <MultiStepDialog
                                open={isModalOpen}
                                handleClose={handleCloseModal}
                            />
                            {!isModalOpen && (
                                <Box
                                    position="fixed"
                                    bottom={"1rem"}
                                    right={"1rem"}
                                >
                                    <PulsingIconButton
                                        color="default"
                                        onClick={handleOpenModal}
                                        aria-label="Open updates dialog"
                                    >
                                        <InfoIcon
                                            style={{ fontSize: "2.5rem" }}
                                            sx={{
                                                color: "white",
                                                opacity: 0.8,
                                            }}
                                        />
                                    </PulsingIconButton>
                                </Box>
                            )}
                        </>
                    )}
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
