import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isRoleAdmin } from "routes/AuthenticationRoute/AuthenticationSlice";

const useStyles = createUseStyles((theme) => ({
    footer: {
        position: "fixed",
        zIndex: 1001,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        paddingTop: theme.calculateSpacing(2),
        paddingRight: theme.calculateSpacing(1),
        paddingLeft: theme.calculateSpacing(1),
        paddingBottom: theme.calculateSpacing(2),
        fontWeight: 600,
        backgroundColor: theme.colors.white,
        height: 40,
        color: theme.colors.text.primary,
        bottom: 0,
        borderTop: "2px solid #E1E1E1",
        width: "100%",
        "@media print": {
            display: "flex",
            justifyContent: "center",
            height: "40px !important",
            padding: "0px !important",
        },
        "& $logo": {
            height: 30,
            "@media print": {
                height: "9px !important",
                marginRight: "1em !important",
            },
        },
        "& $text": {
            ...theme.typography.caption,
            paddingLeft: 30,
            color: "#eb5722",
            "@media print": {
                marginRight: "1em !important",
                fontSize: "8px !important",
            },
        },
        "& $confitential": {
            color: "#000",
            display: "none",
            "@media print": {
                display: "block !important",
                width: "80%",
                textAlign: "right",
                marginLeft: "1em !important",
            },
        },
    },
    logo: {},
    text: {},
    confitential: {},
}));
const Footer = () => {
    const { role } = useSelector((state) => ({
        role: isRoleAdmin(state),
    }));
    const classes = useStyles();
    const { pathname } = useLocation();

    return (
        pathname !== "/ba/main" &&
        pathname !== "/cua/main" && (
            <footer
                className={classes.footer}
                style={
                    pathname === "/ba/main" || pathname === "/cua/main"
                        ? {
                              display: "none",
                          }
                        : null
                }
            >
                <img
                    className={classes.logo}
                    src="/images/fiserv_logo.jpg"
                    alt="fiserv bank logo"
                />
                <span className={classes.text}>
                    Bank Intelligence Solutions
                </span>

                <span className={classes.confitential}>
                    Fiserv Confidential
                </span>
                {role === "ADMIN" &&
                (pathname === "/ba/main" || pathname === "/cua/main") ? (
                    <span
                        style={{
                            marginLeft: "auto",
                            marginRight: "1em",
                            color: "#BBBFBF",
                        }}
                    >
                        Version 20220707
                    </span>
                ) : null}
            </footer>
        )
    );
};

export default Footer;
