import { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loading from "components/@bank-ui/core/Loading";
import LinearProgress from "components/@bank-ui/core/progress/LinearProgress";
import { PATHS, PATHS_BA, PATHS_CUA } from "./paths";
import PrivateRoute from "components/global/PrivateRoute";
import { PRIMARY_MODULES } from "./global";

// parent
const AuthenticationRoute = lazy(() =>
    import("routes/AuthenticationRoute/AuthenticationRoute")
);
const OAuth2Callback = lazy(() => import("components/forms/Oauth2Callback"));
const BankAnalyst = lazy(() =>
    import("routes/BankAnalystRoute/BankAnalystRoute")
);
const CUAnalyst = lazy(() => import("routes/CUAnalystRoute/index"));
const Contact = lazy(() => import("routes/Contact"));
const TemplateView = lazy(() => import("components/landing/TemplateView"));
const ForgotPassword = lazy(() =>
    import("routes/AuthenticationRoute/ForgotPasswordRoute")
);

// market
const MarketSummary = lazy(() => import("routes/MarketSummary"));
const MarketDataReview = lazy(() => import("routes/MarketDataReview"));
const MarketReports = lazy(() => import("routes/MarketReports"));
const MarketProspectList = lazy(() => import("routes/MarketProspectList"));
const MarketProspectDetail = lazy(() => import("routes/MarketProspectDetail"));
const MarketQuantify = lazy(() => import("routes/MarketQuantify"));
const MarketRecommendation = lazy(() => import("routes/MarketRecommendation"));
const MarketMEA = lazy(() => import("routes/MarketMEA"));
const MarketBO = lazy(() => import("routes/MarketBO"));
const MarketExecutiveSummaryMatrix = lazy(() =>
    import("routes/MarketExecutiveSummaryMatrix")
);
const MarketBudgeting = lazy(() => import("routes/MarketBudgeting"));
const MarketCustomDma = lazy(() => import("routes/MarketCustomDma"));
const MarketFileEnhancement = lazy(() =>
    import("routes/MarketFileEnhancement")
);

// market data review routes
const MarketDataReviewTable = lazy(() =>
    import("routes/MarketDataReviewTable")
);
const MarketDataReviewMap = lazy(() => import("routes/MarketDataReviewMap"));
const MarketMEAMap = lazy(() => import("routes/MarketMEAMap"));
const MarketBOMap = lazy(() => import("routes/MarketBOMap"));

// playground
const PlayGround = lazy(() => import("routes/PlayGround"));

export const primaryRoutes = (
    <Suspense fallback={<Loading />}>
        <Switch>
            <Route exact path={PATHS_BA.AUTH} component={AuthenticationRoute} />
            <Route path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
            <PrivateRoute path={"/ba"} component={BankAnalyst} />
            <PrivateRoute path={"/cua"} component={CUAnalyst} />
            <PrivateRoute path={PATHS.CONTACT} component={Contact} />
            <Route path={PATHS_BA.PLAY} component={PlayGround} />
            <PrivateRoute path={PATHS.TEMPLATE} component={TemplateView} />
            <Route path="/callback" component={OAuth2Callback} />
        </Switch>
    </Suspense>
);

export const marketRoutes = (
    <Suspense fallback={<LinearProgress />}>
        <Switch>
            <PrivateRoute
                path={PATHS_BA.MARKET_SUMMARY}
                component={MarketSummary}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_DATAREVIEW}
                component={MarketDataReview}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_DATAREVIEW_MEA_TABLE}
                component={MarketDataReview}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_BRANCH_OPTIMIZATION_TABLE}
                component={MarketDataReview}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_REPORTS}
                component={MarketReports}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_PROSPECTLIST}
                component={MarketProspectList}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_PROSPECTDETAIL}
                component={MarketProspectDetail}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_EXECUTIVE_SUMMARY_MATRIX}
                component={MarketExecutiveSummaryMatrix}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_QUANTIFY}
                component={MarketQuantify}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_RECOMMENDATION}
                component={MarketRecommendation}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_BUDGETING}
                component={MarketBudgeting}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_CUSTOMDMA}
                component={MarketCustomDma}
            />
            <PrivateRoute
                path={PATHS_BA.MARKET_FILE_ENHANCEMENT}
                component={MarketFileEnhancement}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_SUMMARY}
                component={MarketSummary}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_DATAREVIEW}
                component={MarketDataReview}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_REPORTS}
                component={MarketReports}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_PROSPECTLIST}
                component={MarketProspectList}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_PROSPECTDETAIL}
                component={MarketProspectDetail}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_EXECUTIVE_SUMMARY_MATRIX}
                component={MarketExecutiveSummaryMatrix}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_QUANTIFY}
                component={MarketQuantify}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_RECOMMENDATION}
                component={MarketRecommendation}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_BUDGETING}
                component={MarketBudgeting}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_CUSTOMDMA}
                component={MarketCustomDma}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_FILE_ENHANCEMENT}
                component={MarketFileEnhancement}
            />
            <PrivateRoute
                path={PATHS_CUA.MARKET_DATAREVIEW_MEA_TABLE}
                component={MarketDataReview}
            />
        </Switch>
    </Suspense>
);

export const marketDataReviewRoutes = (currentModule) => {
    if (currentModule === PRIMARY_MODULES.BANK_ANALYST) {
        return (
            <Suspense fallback={<LinearProgress />}>
                <PrivateRoute
                    path={PATHS_BA.MARKET_DATAREVIEW_TABLE}
                    component={MarketDataReviewTable}
                />
                <PrivateRoute
                    path={PATHS_BA.MARKET_DATAREVIEW_MAP}
                    component={MarketDataReviewMap}
                />
                <PrivateRoute
                    path={PATHS_BA.MARKET_DATAREVIEW_MEA_TABLE}
                    component={MarketMEA}
                />
                <PrivateRoute
                    path={PATHS_BA.MARKET_MEA_MAP}
                    component={MarketMEAMap}
                />
                <PrivateRoute
                    path={PATHS_BA.MARKET_BRANCH_OPTIMIZATION_TABLE}
                    component={MarketBO}
                />
                <PrivateRoute
                    path={PATHS_BA.MARKET_BO_MAP}
                    component={MarketBOMap}
                />
            </Suspense>
        );
    }
    if (currentModule === PRIMARY_MODULES.CU_ANALYST) {
        return (
            <Suspense fallback={<LinearProgress />}>
                <PrivateRoute
                    path={PATHS_CUA.MARKET_DATAREVIEW_TABLE}
                    component={MarketDataReviewTable}
                />
                <PrivateRoute
                    path={PATHS_CUA.MARKET_DATAREVIEW_MAP}
                    component={MarketDataReviewMap}
                />
                <PrivateRoute
                    path={PATHS_CUA.MARKET_DATAREVIEW_MEA_TABLE}
                    component={MarketMEA}
                />
                <PrivateRoute
                    path={PATHS_CUA.MARKET_MEA_MAP}
                    component={MarketMEAMap}
                />
                <PrivateRoute
                    path={PATHS_CUA.MARKET_BRANCH_OPTIMIZATION_TABLE}
                    component={MarketBO}
                />
                <PrivateRoute
                    path={PATHS_CUA.MARKET_BO_MAP}
                    component={MarketBOMap}
                />
            </Suspense>
        );
    }
    return null;
};
