import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorHandler, getErrorMessage } from "utils/errorHandler";
import { FEATURED_MODULES_MAKRKET } from "constants/global";
import { OAuth2Config } from "src/services/oauth2-config";
import { oauth2Service } from "src/services/oauth2-service";
import storeTokensInSession from "src/utils/storeTokensInSession";
import updateUserObjectInSession from "src/utils/updateUserObjectInSession";

const userObject = JSON.parse(sessionStorage.getItem("userObject")) || {};

const initialState = {
    loading: false,
    token: userObject.token || null,
    refreshToken: null,
    idToken: userObject.token || null,
    logoutUrl: null,
    error: false,
    firstName: userObject.firstName || null,
    lastName: userObject.lastName || null,
    sessionExpire: false,
    tokenExpirationTime: null,
    lastLoginDate: userObject.lastLoginDate || null,
    accessList: userObject.accessList || null,
    activeSelectedProduct: userObject.preferredProduct || "",
    isContractActive: true,
    showChangePassword: false,
    dataAfterValidation: null,
    changePasswordStatus: "",
    forceChangePassword: false,
    role: userObject.role || "",
    restrictedNewMenuMarket: [],
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    passComplexcity: true,
    versionNumberForApi: userObject.version || null,
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setTokens(state, { payload }) {
            state.token = payload.access_token;
            state.refreshToken = payload.refresh_token;
            state.idToken = payload.id_token;
            state.logoutUrl = payload.logoutUrl;
        },
        setError(state, { payload }) {
            state.error = payload;
        },
        setToken(state, { payload }) {
            state.token = payload;
        },
        setSessionExpire(state, { payload }) {
            state.sessionExpire = payload;
        },
        setActiveSelectedProduct(state, { payload }) {
            updateUserObjectInSession("activeSelectedProduct", payload);
            state.activeSelectedProduct = payload;
        },
        setUser(state, { payload }) {
            return { ...state, ...payload };
        },
        logoutSuccess(state) {
            return { ...initialState };
        },
        setContractActive(state, { payload }) {
            state.isContractActive = payload;
        },
        setShowChangePassword(state) {
            state.showChangePassword = !state.showChangePassword;
        },
        setDataAfterValidation(state, { payload }) {
            state.dataAfterValidation = payload;
        },
        setChangePasswordStatus(state, { payload }) {
            state.changePasswordStatus = payload;
        },
        setForceChangePassword(state, { payload }) {
            state.forceChangePassword = payload;
        },
        setVersionNumberForApi(state, { payload }) {
            state.versionNumberForApi = payload;
        },
        setNewFilterdNav(state, { payload }) {
            state.restrictedNewMenuMarket = payload;
        },
        setCurrentPassword(state, { payload }) {
            state.currentPassword = payload;
        },
        setNewPassword(state, { payload }) {
            state.newPassword = payload;
        },
        setConfirmPassword(state, { payload }) {
            state.confirmPassword = payload;
        },
        setPassComplexcity(state, { payload }) {
            state.passComplexcity = payload;
        },
    },
});

export const {
    setLoading,
    setToken,
    setActiveSelectedProduct,
    setUser,
    logoutSuccess,
    setSessionExpire,
    setContractActive,
    setShowChangePassword,
    setDataAfterValidation,
    setChangePasswordStatus,
    setForceChangePassword,
    setNewFilterdNav,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    setPassComplexcity,
    setVersionNumberForApi,
    setTokens,
    setError,
} = authenticationSlice.actions;

export const logoutUser = () => async (dispatch) => {
    const token = sessionStorage.getItem("token") || null;
    try {
        dispatch(setLoading(true));
        await dispatch(closeUserSession(token));
        await oauth2Service.handleLogout(() => {
            sessionStorage.clear();
            localStorage.clear();
            dispatch(logoutSuccess());
        });
    } catch (error) {
        console.error("Error during logout: ", error);
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(false));
    }
};

export const handleOAuth2Callback = (code, history) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const codeVerifier = localStorage.getItem("codeVerifier");
        if (!codeVerifier) throw new Error("nothing found in local storage");

        const data = JSON.stringify({
            authorizationCode: code,
            codeVerifier,
            redirectURL: OAuth2Config.callbackUrl,
            scope: "openid email profile",
        });

        const config = {
            method: "post",
            url: OAuth2Config.accessTokenUrl,
            headers: { "Content-Type": "application/json" },
            data,
        };

        const response = await axios.request(config);
        const {
            accessToken,
            refreshToken,
            idToken,
            logoutUrl,
            username,
            expiresIn,
        } = response.data;

        storeTokensInSession({
            access_token: accessToken,
            refresh_token: refreshToken,
            id_token: idToken,
            logoutUrl,
            token: idToken,
            username,
            expiresIn,
        });

        dispatch(setTokens({ accessToken, refreshToken, idToken, logoutUrl }));
        dispatch(setToken(idToken));
        dispatch(authenticateUser(idToken, history));
    } catch (error) {
        dispatch(setError(true));
        errorHandler(getErrorMessage(error));
        setTimeout(() => history.push("/"), 3000);
    } finally {
        dispatch(setLoading(false));
    }
};

export const authenticateUser = (token, history) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_BASE_URL}/user/profile`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        const data = response.data;
        const userObject = { ...data, token };

        sessionStorage.setItem("userObject", JSON.stringify(userObject));
        dispatch(setUser(userObject));
        dispatch(setToken(token));
        dispatch(setActiveSelectedProduct(userObject.preferredProduct));
        dispatch(setVersionNumberForApi(data?.version));

        const userName = sessionStorage.getItem("username");
        const newRefreshToken = sessionStorage.getItem("refresh_token");
        const callRefreshTokenIn = 270000 || 3599000;

        setTimeout(
            () => dispatch(refreshToken(userName, token, newRefreshToken)),
            callRefreshTokenIn
        );
    } catch (error) {
        dispatch(logoutUser());
        dispatch(setError(true));
        errorHandler(getErrorMessage(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const refreshToken =
    (userName, token, testRefreshToken) => async (dispatch, getState) => {
        dispatch(setLoading(true));
        const refreshVerifyJwtToken = sessionStorage.getItem("id_token");

        try {
            const { data } = await axios({
                url: `${import.meta.env.VITE_BASE_URL}/user/refreshtoken`,
                headers: {
                    Authorization: `Bearer ${token || refreshVerifyJwtToken}`,
                },
                method: "POST",
                data: {
                    refreshToken: testRefreshToken,
                    scope: "openid email profile",
                },
            });

            if (!data.idToken) {
                dispatch.push("/");
                return;
            }

            const {
                accessToken,
                refreshToken: apiRefreshToken,
                idToken,
                logoutUrl,
                expiresIn,
                username,
            } = data;

            dispatch(
                setTokens({
                    accessToken,
                    refreshToken: apiRefreshToken,
                    idToken,
                    logoutUrl,
                })
            );
            dispatch(setToken(data.idToken));

            storeTokensInSession({
                access_token: accessToken,
                refresh_token: apiRefreshToken,
                id_token: idToken,
                logoutUrl,
                token: idToken,
                expiresIn,
                username,
            });

            const userObject = JSON.parse(sessionStorage.getItem("userObject"));
            userObject.token = data.idToken;
            sessionStorage.setItem("userObject", JSON.stringify(userObject));

            setTimeout(
                () =>
                    dispatch(refreshToken(userName, idToken, apiRefreshToken)),
                270000
            );
        } catch (error) {
            dispatch(setError(true));
            errorHandler(getErrorMessage(error));
            dispatch(setSessionExpire(true));
            dispatch(logoutUser());
        } finally {
            dispatch(setLoading(false));
        }
    };

export const closeUserSession = (token) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_BASE_URL}/user/closeSession`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        dispatch(setLoading(false));
        return response.data;
    } catch (error) {
        dispatch(setError(true));
        console.error("Session closure error:", error.message);
        errorHandler(getErrorMessage(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const restrictedMenuList =
    (subNavItems, restricted, id) => async (dispatch, getState) => {
        const newSubmenu = [];
        const { role } = getState().authentication;
        dispatch(setLoading(true));
        if (id === "market" && restricted && subNavItems.length > 0) {
            subNavItems.forEach((item) => {
                restricted.forEach((restrictedItem) => {
                    if (
                        FEATURED_MODULES_MAKRKET[restrictedItem.feature] ===
                            item.id &&
                        !restrictedItem.accessible
                    ) {
                        newSubmenu.push(item);
                    }
                    if (
                        role !== "ADMIN" &&
                        ["MarketBO", "MarketExpansion"].includes(item.id)
                    ) {
                        newSubmenu.push(item);
                    }
                });
            });
        }
        if (newSubmenu.length > 0 && id === "market") {
            const latest = subNavItems.filter(
                (item) =>
                    !newSubmenu.some((newItem) => newItem.id === item.id) ||
                    item.id === "marketReports"
            );
            dispatch(setNewFilterdNav(latest));
        } else {
            dispatch(setNewFilterdNav(subNavItems));
        }
    };

export const getAuthLoading = (state) => state.authentication.loading;
export const isAuthenticated = ({ authentication }) =>
    !!authentication.firstName;
export const token = ({ authentication }) => authentication.token;
export const accessList = ({ authentication }) => authentication.accessList;
export const bankAnalystCustomDownloadAccess = ({ authentication }) =>
    authentication.accessList[0].modules[0].restrictedFeatures[0].accessible;
export const cuaAnalystCustomDownloadAccess = ({ authentication }) =>
    authentication.accessList[1].modules[0].restrictedFeatures[0].accessible;
export const activeSelectedProduct = ({ authentication }) =>
    authentication.activeSelectedProduct;
export const getAuthenticatedUser = (state) => state.authentication;
export const getAccessList = (state) => state.authentication;
export const getProductPreference = (state) => state.preferredProduct;
export const contract_active = (state) => state.authentication.isContractActive;
export const show_change_password = (state) =>
    state.authentication.showChangePassword;
export const data_after_validation = (state) =>
    state.authentication.dataAfterValidation;
export const change_password_status = (state) =>
    state.authentication.changePasswordStatus;
export const force_change_password = (state) =>
    state.authentication.forceChangePassword;
export const marketCustomdownload = ({ authentication }) =>
    authentication.accessList[0].modules[0].restrictedFeatures[0].accessible;
export const bankAnalystFinancialRestrictedFeatures = ({ authentication }) =>
    authentication.accessList[0].modules[0].restrictedFeatures;
export const cuaAnalystFinancialRestrictedFeatures = ({ authentication }) =>
    authentication.accessList[1].modules[0].restrictedFeatures;
export const bankAnalystMarketRestrictedFeatures = ({ authentication }) =>
    authentication.accessList[0].modules[1].restrictedFeatures;
export const cuaAnalystMarketRestrictedFeatures = ({ authentication }) =>
    authentication.accessList[1].modules[1].restrictedFeatures;
export const restrictedNewMenuMarket_auth = (state) =>
    state.authentication.restrictedNewMenuMarket;
export const current_password = (state) => state.authentication.currentPassword;
export const new_password = (state) => state.authentication.newPassword;
export const confirm_password = (state) => state.authentication.confirmPassword;
export const complexcity_password = (state) =>
    state.authentication.passComplexcity;
export const isRoleAdmin = (state) => state.authentication.role;
export const versionForApi = ({ authentication }) =>
    authentication.versionNumberForApi;
export const isAuthFailed = (state) => state.authentication.error;
export const isLoggedIn = (state) => !!state.authentication.firstName;

export default authenticationSlice.reducer;
