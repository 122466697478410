/* eslint-disable eqeqeq */
import _ from "lodash";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { createUseStyles } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import Header from "components/Header";
import Footer from "components/global/Footer";
import {
    isAuthenticated,
    show_change_password,
    setShowChangePassword,
    getAuthenticatedUser,
    force_change_password,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    setDataAfterValidation,
} from "routes/AuthenticationRoute/AuthenticationSlice";
import {
    setShowReportUpdated,
    updateOnVesrionChange,
} from "routes/ReportsRoute/ReportsSlice";
import { useLocation } from "react-router-dom";
import { Drawer } from "components/navigation/Drawer/Drawer";
import ICONS from "components/IconRepository/IconRepository";
import Icon from "components/Icon/Icon";
import Typography from "components/global/Typography";
//import ChangePasswordForm from "components/forms/ChangePasswordForm";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { logoutUser } from "src/routes/AuthenticationRoute/AuthenticationSlice";

const LayoutContainer = styled.div`
    width: 100%;
`;

const Content = styled.div`
    ${({ authenticated }) =>
        authenticated &&
        css`
            padding-top: 68px;
        `}
`;

const useStyles = createUseStyles((theme) => ({
    changePasswordDrawer: {
        borderRight: `2px solid ${theme.colors.greys.disabled}`,
        zIndex: 9999,
        backgroundColor: theme.colors.white,
        boxShadow: theme.getShadow(2, "dark"),
        "@media print": {
            width: "none !important",
        },
    },
    drawerHeader: {
        height: 185,
        padding: 20,
        backgroundColor: "#3675D0",
    },
    drawerheading: {
        lineHeight: `36px`,
        paddingLeft: 10,
        fontSize: 26,
        color: "#fff",
        display: "inline-flex",
        fontWeight: 400,
        width: "59%",
        marginTop: 50,
    },
    close: {
        cursor: "pointer",
        display: "inline-flex",
        float: "right",
        "& g": {
            fill: "rgb(255, 255, 255)",
        },
    },
    disableBackground: {
        pointerEvents: "none",
    },
    snackBar: {
        "& div": {
            backgroundColor: "#ff9800",
        },
    },
    disableFooter: {
        display: "none !important",
    },
}));

export default function PrimaryLayout({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const authenticated = useSelector((state) => isAuthenticated(state));
    const showChangePassword = useSelector(show_change_password);
    const forceChangePassword = useSelector(force_change_password);
    const { firstName, lastName, userName, token } = useSelector((state) =>
        getAuthenticatedUser(state)
    );
    const [forceUserToChangePassword, setForceUserToChangePassword] = useState(
        JSON.parse(sessionStorage.getItem("userObject"))?.forceChangePassword ||
            false
    );

    function logOut() {
        dispatch(logoutUser());
    }

    useEffect(() => {
        let restrict = JSON.parse(
            sessionStorage.getItem("userObject")
        )?.forceChangePassword;
        setForceUserToChangePassword(restrict);

        if (restrict && !_.includes(pathname, "main")) {
            sessionStorage.clear();
            window.location.reload();
        }

        let accessList = JSON.parse(
            sessionStorage.getItem("userObject")
        )?.accessList;

        if (accessList) {
            let moduleSpecificList =
                pathname.includes("cua") && accessList
                    ? accessList[1].modules.find(
                          (object) => object.shortName === "MARKET"
                      )
                    : accessList[0].modules.find(
                          (object) => object.shortName === "MARKET"
                      );

            let foundProspect =
                moduleSpecificList &&
                moduleSpecificList?.restrictedFeatures.find(
                    (eachFeature) =>
                        eachFeature.feature === "ORDER_PROSPECT_LISTS"
                );
            if (
                (_.includes(pathname, "/cua/market/prospect") ||
                    _.includes(pathname, "/ba/market/prospect")) &&
                foundProspect &&
                foundProspect.accessible === false
            )
                logOut();

            let foundCustomPeer =
                moduleSpecificList &&
                moduleSpecificList?.restrictedFeatures.find(
                    (eachFeature) =>
                        eachFeature.feature === "CREATE_CUSTOM_PEER"
                );
            if (
                (_.includes(
                    pathname,
                    "/ba/financial/peerSelection/customePeers"
                ) ||
                    _.includes(
                        pathname,
                        "/cua/financial/peerSelection/customePeers"
                    )) &&
                foundCustomPeer &&
                foundCustomPeer.accessible === false
            )
                logOut();

            let foundFileEnhancement =
                moduleSpecificList &&
                moduleSpecificList?.restrictedFeatures.find(
                    (eachFeature) =>
                        eachFeature.feature === "ORDER_FILE_ENHANCEMENT"
                );
            if (
                (_.includes(pathname, "/ba/market/file/enhancement") ||
                    _.includes(pathname, "/cua/market/file/enhancement")) &&
                foundFileEnhancement &&
                foundFileEnhancement.accessible === false
            )
                logOut();

            let foundMEA =
                moduleSpecificList &&
                moduleSpecificList?.restrictedFeatures.find(
                    (eachFeature) => eachFeature.feature === "MEA_EXPANSION"
                );
            if (
                (_.includes(pathname, "/ba/market/datareview/mea") ||
                    _.includes(pathname, "/cua/market/datareview/mea")) &&
                foundMEA &&
                foundMEA.accessible === false
            )
                logOut();
        }
    }, [pathname]);

    const onClose = () => {
        dispatch(setCurrentPassword(""));
        dispatch(setNewPassword(""));
        dispatch(setConfirmPassword(""));
        dispatch(setDataAfterValidation(""));
        if (forceUserToChangePassword || forceChangePassword) {
            sessionStorage.clear();
            window.location.reload();
        } else {
            dispatch(setShowChangePassword());
        }
    };

    const handleClose = () => {
        dispatch(setShowReportUpdated(false));
    };

    const handleRefresh = () => {
        dispatch(setShowReportUpdated(false));
        dispatch(updateOnVesrionChange(true));
    };

    return (
        <LayoutContainer>
            <Snackbar
                className={classes.snackBar}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={false}
                onClose={handleClose}
                message="Report is updated. Do you want to see updated information?"
                action={
                    <>
                        <Button
                            color="secondary"
                            size="small"
                            onClick={handleRefresh}
                        >
                            YES
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
            {/* <Drawer
                rightWidth={800}
                className={classes.changePasswordDrawer}
                visible={forceUserToChangePassword || showChangePassword}
                anchor={"right"}
            >
                <div className={classes.drawerHeader}>
                    <Typography
                        component="h2"
                        className={classes.drawerheading}
                    >
                        Change Password - {" " + firstName + " " + lastName}
                    </Typography>
                    <Icon
                        className={classes.close}
                        IconComponentName={ICONS.Close}
                        width={"20"}
                        height={"20"}
                        onClick={() => onClose()}
                    />
                </div>
                <ChangePasswordForm token={token} userName={userName} />
            </Drawer> */}
            {pathname == "/templateView" ? null : authenticated && <Header />}
            <Content authenticated={authenticated}>{children}</Content>
            {authenticated &&
                !_.includes(pathname, "/contact") &&
                !_.includes(pathname, "/prospectDetail") &&
                !_.includes(pathname, "/play") &&
                !_.includes(pathname, "/ba/main") &&
                !_.includes(pathname, " /cua/main") && (
                    <Footer
                        className={
                            _.includes(pathname, "/ba/main") ||
                            _.includes(pathname, "/cua/main")
                                ? classes.disableFooter
                                : null
                        }
                    />
                )}
        </LayoutContainer>
    );
}

PrimaryLayout.propTypes = {
    children: PropTypes.element,
};
