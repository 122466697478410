import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { pxToRem } from "utils/getStyles";
import { ReactComponent as Batman } from "assets/icons/icon-profile.svg";
import { userMenu } from "constants/userMenu";
import Svg from "components/@bank-ui/core/Svg";
import { FlexWrapper } from "components/@bank-ui/core/Wrapper/FlexWrapper";
import { blue } from "@mui/material/colors";
import { getAuthenticatedUser } from "routes/AuthenticationRoute/AuthenticationSlice";

const Container = styled.div`
    position: relative;
    cursor: pointer;
`;

const UserNameWrapper = styled.div`
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 12px;
    text-transform: none;
    color: ${(p) => p.theme.palette.text.accent.blueDark};
    p {
        margin: 0px;
        padding: 0px;
    }
    .user-name {
        width: max-content;
        font-size: 16px;
        line-height: 16px;
    }
    .user-title {
        font-size: ${pxToRem(16)};
        line-height: 14px;
        width: max-content;
    }
`;

const UserContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;

    @media (min-width: 1280px) {
        ${UserNameWrapper} {
            display: flex;
        }
    }
`;

export const StyledSvg = styled(Svg)`
    height: 16px;
    width: 16px;
    fill: ${(p) => p.theme.palette.text.accent.blueDark};
    margin-right: 16px;
`;

const UserMenu = styled.div`
  position: absolute;
  top: 50px;
  left: 1rem;
  width: 200px;
  margin: 0px;
  padding: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 324px;
  z-index: 10;
  background-color: ${(p) => p.theme.palette.background.paper};
  border: 1px solid ${(p) => p.theme.palette.divider};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    li {
      cursor: pointer;
      list-style: none;
      display: block;
      width: 100%;
      font-size: ${pxToRem(16)};
      line-height: 16px;
      padding: 12px;
      margin: 0;
      height: auto;
      white-space: nowrap;
      text-decoration: none;
      color: ${(p) => p.theme.palette.text.accent.blue};
      border-radius: ${(p) => p.theme.shape.borderRadius}px;

      &:hover {
        color: ${(p) => p.theme.palette.text.accent.green};
        background-color: ${blue[50]};
      }

      &:hover ${StyledSvg} {
        fill: ${(p) => p.theme.palette.text.accent.green};
      }
`;

const LastLogin = styled.div`
    font-size: 14px;
    color: #212121;
    border-top: 1px solid #dedede;
    cursor: default;
    span {
        font-weight: 600;
    }
`;

export default function User({ userInfo: { firstName, lastName } }) {
    const userRef = useRef(null);
    const dispatch = useDispatch();
    const { lastLoginDate } = useSelector((state) =>
        getAuthenticatedUser(state)
    );
    const [showMenu, setShowMenu] = useState(false);

    const closeMenu = useCallback(() => {
        setTimeout(() => {
            setShowMenu(false);
        }, 1500);
    }, [setShowMenu]);

    function handleUserClick() {
        return showMenu ? closeMenu() : setShowMenu(true);
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (userRef.current && !userRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [userRef, setShowMenu]);

    async function handleOnSelect() {
        setShowMenu(false);
    }

    return (
        <Container ref={userRef} onClick={() => handleUserClick()}>
            <UserContainer>
                {/* <Avatar src={batman} alt="user-avatar" /> */}
                <Batman width="2rem" height="2rem" />
                {firstName && lastName && (
                    <UserNameWrapper>
                        <p className="user-name">{`${firstName} ${lastName}`}</p>
                        {/* <p className="user-title">Batman</p> */}
                    </UserNameWrapper>
                )}
            </UserContainer>
            {showMenu && (
                <UserMenu>
                    {_.map(userMenu, (option, index) => {
                        return (
                            <li
                                key={index}
                                onClick={async () => {
                                    await handleOnSelect();
                                    dispatch(option.onClick());
                                }}
                            >
                                <FlexWrapper>
                                    {option.icon && (
                                        <StyledSvg SvgComponent={option.icon} />
                                    )}
                                    {option.label}
                                </FlexWrapper>
                            </li>
                        );
                    })}
                    <LastLogin>
                        <span>Last Login : </span> {lastLoginDate}{" "}
                    </LastLogin>
                </UserMenu>
            )}
        </Container>
    );
}
