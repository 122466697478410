import { ReactComponent as DownloadIcon } from "assets/icons/fa-download.svg";
import { ReactComponent as ListAltIcon } from "assets/icons/list-alt.svg";
import { ReactComponent as SuitcaseIcon } from "assets/icons/suitcase.svg";
import { ReactComponent as MapIcon } from "assets/icons/map.svg";
import { ReactComponent as DollarSignIcon } from "assets/icons/dollar-sign.svg";
import { ReactComponent as InvoiceReceiptIcon } from "assets/icons/invoice-receipt.svg";
import { ReactComponent as ProjectDiagramIcon } from "assets/icons/project-diagram-solid.svg";
import { PATHS_BA, PATHS_CUA } from "./paths";
import ICONS from "components/IconRepository/IconRepository";

export const bankAnalystPrimaryNav = [
    {
        id: "home",
        label: "Home",
        path: PATHS_BA.HOME,
        icon: null,
        subNavItems: [],
    },
    {
        id: "finance",
        label: "Financial",
        path: PATHS_BA.FINANCIAL,
        icon: null,
        subNavItems: [
            {
                id: "DashBoard",
                label: "Dashboard",
                path: PATHS_BA.FINANCIAL,
                icon: ICONS.Dashboard,
                subNavItems: [],
            },
            {
                id: "ratio",
                label: "Ratios",
                path: PATHS_BA.FINANCIAL_RATIOS,
                icon: ICONS.Ratio,
                subNavItems: [],
            },
            {
                id: "reports",
                label: "Reports",
                path: PATHS_BA.FINANCIAL_REPORTS,
                icon: ICONS.Report,
                subNavItems: [],
            },
            {
                id: "peers",
                label: "Peers",
                path: PATHS_BA.FINANCIAL_PEERSELECTION,
                icon: ICONS.Peers,
                subNavItems: [],
            },
            {
                id: "financialReports",
                label: "Custom Download Reports",
                path: PATHS_BA.FINANCIA_CUSTOMREPORTS,
                icon: DownloadIcon,
                subNavItems: [],
            },
        ], //TODO: add Finance subnav routes
    },
    {
        id: "market",
        label: "Market",
        path: PATHS_BA.MARKET,
        icon: null,
        subNavItems: [
            {
                id: "marketSummary",
                label: "Summary Overview",
                path: PATHS_BA.MARKET_SUMMARY,
                icon: SuitcaseIcon,
                subNavItems: [],
            },
            {
                id: "marketReports",
                label: "Custom Download Reports",
                path: PATHS_BA.MARKET_REPORTS,
                icon: DownloadIcon,
                subNavItems: [],
            },
            {
                id: "marketProspectLists",
                label: "Prospect Lists",
                path: PATHS_BA.MARKET_PROSPECTLIST,
                icon: ListAltIcon,
                subNavItems: [],
            },
            // {
            //     id: "marketBudgeting",
            //     label: "Budgeting",
            //     path: PATHS_BA.MARKET_BUDGETING,
            //     icon: DollarSignIcon,
            //     subNavItems: [],
            // },
            {
                id: "customDMA",
                label: "Custom DMA",
                path: PATHS_BA.MARKET_CUSTOMDMA,
                icon: MapIcon,
                subNavItems: [],
            },
            {
                id: "fileEnhancement",
                label: "Consumer File Enhancement",
                path: PATHS_BA.MARKET_FILE_ENHANCEMENT,
                icon: InvoiceReceiptIcon,
                subNavItems: [],
            },
            {
                id: "MarketBO",
                label: "Branch Optimization",
                path: PATHS_BA.MARKET_BRANCH_OPTIMIZATION_TABLE,
                icon: ProjectDiagramIcon,
                subNavItems: [],
            },
            {
                id: "MarketExpansion",
                label: "MEA - Market Expansion Analysis",
                path: PATHS_BA.MARKET_DATAREVIEW_MEA_TABLE,
                icon: SuitcaseIcon,
                subNavItems: [],
            },           
        ],
    },
    {
      id: 'risk',
      label: 'Risk',
      path: PATHS_BA.RISK,
      icon: null,
      subNavItems: []
    }
];

export const cuAnalystPrimaryNav = [
    {
        id: "home",
        label: "Home",
        path: PATHS_CUA.HOME,
        icon: null,
        subNavItems: [],
    },
    {
        id: "finance",
        label: "Financial",
        path: PATHS_CUA.FINANCIAL,
        icon: null,
        subNavItems: [
            {
                id: "DashBoard",
                label: "Dashboard",
                path: PATHS_CUA.FINANCIAL,
                icon: ICONS.Dashboard,
                subNavItems: [],
            },
            {
                id: "ratio",
                label: "Ratios",
                path: PATHS_CUA.FINANCIAL_RATIOS,
                icon: ICONS.Ratio,
                subNavItems: [],
            },
            {
                id: "reports",
                label: "Reports",
                path: PATHS_CUA.FINANCIAL_REPORTS,
                icon: ICONS.Report,
                subNavItems: [],
            },
            {
                id: "peers",
                label: "Peers",
                path: PATHS_CUA.FINANCIAL_PEERSELECTION,
                icon: ICONS.Peers,
                subNavItems: [],
            },
            {
                id: "financialReports",
                label: "Custom Download Reports",
                path: PATHS_CUA.FINANCIA_CUSTOMREPORTS,
                icon: DownloadIcon,
                subNavItems: [],
            },
        ], //TODO: add Finance subnav routes
    },
    {
        id: "market",
        label: "Market",
        path: PATHS_CUA.MARKET,
        icon: null,
        subNavItems: [
            {
                id: "marketSummary",
                label: "Summary Overview",
                path: PATHS_CUA.MARKET_SUMMARY,
                icon: SuitcaseIcon,
                subNavItems: [],
            },
            {
                id: "marketReports",
                label: "Custom Download Reports",
                path: PATHS_CUA.MARKET_REPORTS,
                icon: DownloadIcon,
                subNavItems: [],
            },
            {
                id: "marketProspectList",
                label: "Prospect Lists",
                path: PATHS_CUA.MARKET_PROSPECTLIST,
                icon: ListAltIcon,
                subNavItems: [],
            },
            // {
            //     id: "marketBudgeting",
            //     label: "Budgeting",
            //     path: PATHS_CUA.MARKET_BUDGETING,
            //     icon: DollarSignIcon,
            //     subNavItems: [],
            // },
            {
                id: "customDMA",
                label: "Custom DMA",
                path: PATHS_CUA.MARKET_CUSTOMDMA,
                icon: MapIcon,
                subNavItems: [],
            },
            {
                id: "fileEnhancement",
                label: "Consumer File Enhancement",
                path: PATHS_CUA.MARKET_FILE_ENHANCEMENT,
                icon: InvoiceReceiptIcon,
                subNavItems: [],
            },
            {
                id: "MarketBO",
                label: "Branch Optimization",
                path: PATHS_CUA.MARKET_BRANCH_OPTIMIZATION_TABLE,
                icon: ProjectDiagramIcon,
                subNavItems: [],
            },
            {
                id: "MarketExpansion",
                label: "MEA - Market Expansion Analysis",
                path: PATHS_CUA.MARKET_DATAREVIEW_MEA_TABLE,
                icon: SuitcaseIcon,
                subNavItems: [],
            },           
        ],
    },
    // {
    //   id: 'risk',
    //   label: 'Risk',
    //   path: PATHS_CUA.RISK,
    //   icon: null,
    //   subNavItems: []
    // }
];


//src/constants/bankAnalystPrimaryNav.js
//empty commit.
